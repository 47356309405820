import React from "react";
import { graphql } from "gatsby";
import GatsbyLink from "../components/GatsbyLink";
import Previewable from "../components/Previewable";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { PageHeader } from "../components/PageHeader";
import * as AcfLayout from "../components/Acf";
import { RenderShortcode } from "../components/RenderShortcode";
import { ImagePass } from "../components/Image/ImagePass";
import "./post.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";
import loadable from "@loadable/component";

const loadableComponents = [];

const AcfComponent = ({
  location,
  componentName,
  item,
  pageContext,
  index,
}) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = loadableComponents.includes(componentName)
    ? loadable((props) =>
        import(`../components/Acf/${componentName}/index.jsx`)
      )
    : AcfLayout[componentName];

  const componentTitle = item.__typename
    ? item.__typename.replace("WordPressAcf_", "")
    : "No name";
  if (!ComponentName) {
    return (
      <section className="page missing">
        <div className="inner">
          The <strong>"{componentTitle}"</strong> component is missing.{" "}
          <span>&#128540;</span>
        </div>
      </section>
    );
  } else {
    return (
      <ComponentName
        index={index}
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }
};

export const ProjectPostTemplate = ({
  content,
  categories,
  title,
  date,
  author,
}) => {
  return (
    <section className="single-post">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="wrap">
              <RenderShortcode content={content} />
            </div>
            {categories && categories.length ? (
              <div className="taxonomy">
                <h4>Categories</h4>
                <ul className="taglist">
                  {categories.map((category) => (
                    <li key={`${category.slug}cat`}>
                      {category.slug && (
                        <GatsbyLink to={`/projects/category/${category.slug}/`}>
                          {category.name}
                        </GatsbyLink>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectPost = (props) => {
  const { data, pageContext, location } = props;
  const { wordpressPost: post, wordpressWpSettings, siteSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  if (!post) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    categories,
    date,
    author,
    acf,
  } = post;

  const { layout } = acf || {
    layout: null,
  };

  return (
    <Layout location={location} wordpressUrl={wordpressUrl}>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={
          featured_media &&
          featured_media.source_url &&
          featured_media.source_url
        }
        location={location}
      />
      {layout &&
        layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace(
            "WordPressAcf_",
            ""
          );

          return (
            <AcfComponent
              key={index}
              index={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
              pageContext={pageContext}
            />
          );
        })}
    </Layout>
  );
};

export default Previewable(ProjectPost, "projects");

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
        }
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        layout: layout_projects_projects {
          __typename
          ...ImageSliderQuery
          ...HeaderQuery
          ...ProjectDetailsQuery
          ...BlogFeedQuery
        }
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`;
